import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import React from 'react';
import { MotionSectionHeading } from '../../../section-heading';
import * as style from './index.module.scss';

export default function WeCanHelp() {
  const items: { title: string; desc: string }[] = [
    {
      title: 'Should we go, or should we stay',
      desc: 'Search for your real-time real estate listings, pick out your new home and get connected with an approved Realtor.'
    },
    {
      title: 'Is refinancing the right more?',
      desc: 'Sure rate are low, but not all mortgages are created equally. Let our team explore new lending options, for free.'
    },
    {
      title: 'Renovate with a home credit Line',
      desc: 'Upgrade your kitchen, bathrooms, or basement using the equity in your home and a low rate credit line.'
    },
    {
      title: 'End the minimum payment cycle.',
      desc: 'Use the equity in your home to jump off the credit card minimum payment hamster wheel and same more money.'
    }
  ];

  return (
    <section
      className={
        style.weCanHelp + ' d-block d-md-flex flex-column align-items-center justify-content-center'
      }
    >
      <div className={style.wrapper + ' container d-md-flex flex-column align-items-center'}>
        <header className="text-md-center">
          <MotionSectionHeading>"Yeah, we can help with that."</MotionSectionHeading>
        </header>

        <div className={style.details}>{items.map((item) => helpItem(item.title, item.desc))}</div>
      </div>
    </section>
  );
}

const helpItem = (title: string, desc: string) => {
  return (
    <div key={title} className={style.itemContainer + ' d-flex'}>
      <div className={style.itemIcon}>
        <FaCheck />
      </div>
      <div className={style.content + ' d-flex flex-column'}>
        <h3 className={style.itemTitle}>{title}</h3>
        <p className={style.itemDesc}>{desc}</p>
      </div>
    </div>
  );
};
