import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { EquityIntoCashQuery } from '../../../../../graphql-types';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './index.module.scss';

export default function CallUs() {
  const data: FeatureSectionProps = {
    items: [
      {
        largeTitle: true,
        title: (
          <>
            Have Questions? <br />
            We have answers.
          </>
        ),
        subtitle: 'Call, email or text our client care team',
        description: ``,
        actions: [
          { label: `Call 888 372 5588`, href: 'https://apply.nuborrow.com', isPrimary: true },
          { label: `Text 888 372 5588`, href: 'https://apply.nuborrow.com' }
        ],
        art: callUsArt()
      }
    ]
  };

  return <FeatureSection {...data} className={style.callUs} />;
}

export const callUsArt = () => {
  const bgImage = useStaticQuery<EquityIntoCashQuery>(dataQuery);

  return (
    <div className={'d-flex align-items-center justify-content-center'}>
      <ParallaxBackground
        className={style.callUsArt}
        image={bgImage.file as unknown as ImageDataLike}
      />
    </div>
  );
};

const dataQuery = graphql`
  query CallUsQuery {
    file(relativePath: { glob: "photos/contact-us.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.625, width: 1000)
      }
      relativePath
    }
  }
`;
