import React from 'react';
import ContactSection from '../components/contact-section';
import Layout from '../components/layout';
import CallUs from '../components/sections/care/call-us';
import WeCanHelp from '../components/sections/care/we-can-help';

export default function CarePage() {
  return (
    <Layout pageTitle={'We Care'}>
      <CallUs />
      <WeCanHelp />
      <ContactSection />
    </Layout>
  );
}
